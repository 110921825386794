@import '../theme';

.scrollarea {
  height: 100vh;
}

.scrollarea.area {
  height: calc(~"100vh - 250px");
}

.header_right {
  list-style: none;
}

.blueBtn.ant-btn {
  color: #fff;
  background: @dark-blue;
  border-color: @dark-blue;
  &:hover, &:focus {
    color: #fff;
    background-color: darken(@dark-blue, 5%);
    border-color: darken(@dark-blue, 5%);
  }
}

.ant-layout-content.main-content {
  padding: 24px 24px 0;
  min-height: calc(~"100vh - "@header-height);
}

.spacer {
  flex-grow: 1;
}

.spacing {
  width: 10px;
}


/* overwrite */
.ant-btn:hover {
  color: @dark-blue;
  border-color: @dark-blue;
}

.ant-btn-primary:not([disabled]).ant-btn:hover,
.ant-btn-primary:not([disabled]).ant-btn:focus {
  color: #fff;
  border-color: @dark-blue;
}

.ant-input:focus {
  border-color: @dark-blue;
}
